export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_FAILURE = "SETTINGS_FAILURE";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";

export type SettingsType = {
  vehicles: Array<SettingsVehicles>;
};

export type SettingsVehicles = {
  DCM: string;
  Location: string;
  AssetNumber: string;
  Timezone: string;
  CustomerGroup: string;
  UpdateBy: string;
  LastUpdate: string;
  MCUFirmwareVersion: string;
  DCMFirmwareVersion: string;
};

export interface SettingsLoading {
  type: typeof SETTINGS_LOADING;
}

export interface SettingsFailure {
  type: typeof SETTINGS_FAILURE;
}

export interface SettingsSuccess {
  type: typeof SETTINGS_SUCCESS;
  payload: Array<SettingsVehicles>;
}

export type SettingsDispatchTypes =
  | SettingsLoading
  | SettingsFailure
  | SettingsSuccess;