import {
  RouteDispatchTypes,
  ROUTE_LOADING,
  ROUTE_FAILURE,
  ROUTE_SUCCESS,
  VehicleRoute,
} from "../actions/routeActionTypes";
import { VehicleData } from "../actions/vehicleActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  routes?: Array<VehicleRoute>;
  timestamp?: string;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const routeReducer = (
  state: DefaultStateInterface = defaultState,
  action: RouteDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case ROUTE_FAILURE:
      return {
        loading: false,
      };
    case ROUTE_LOADING:
      return {
        loading: true,
      };
    case ROUTE_SUCCESS:
      return {
        loading: false,
        routes: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default routeReducer;
